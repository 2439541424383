<template>
  <div>
    <form-field :name="field.name">
      <v-text-field
        v-model="field.value"
        v-bind="field.props"
        box
      />
    </form-field>
    <v-dialog
      v-model="notification"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      max-width="350px"
    >
      <v-card color="info">
        <v-card-title class="headline">
          Reminder
        </v-card-title>
        <v-card-text v-html="alertText" />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            @click.native="notification = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import formField from '@/mixins/form-field'
import { DateTime } from 'luxon'
import { createGetParams } from '@/rest'

const idMask = 'NNNNNNNNNNNN'
const myKadMask = '######-##-####'

export default {
  mixins: [formField],
  data () {
    return {
      alertText: '',
      notification: false,
      source: null,
    }
  },
  watch: {
    'field.value': function (val) {
      this.field.props.loading = false
      if (!val || val.length < 6) {
        this.field.props = Object.assign({}, this.field.props, { mask: idMask })
        return
      }

      const year = val.substr(0, 2)
      if (isNaN(year)) return

      const month = val.substr(2, 2)
      if (isNaN(month)) return

      const day = val.substr(4, 2)
      if (isNaN(day)) return

      const date = DateTime.fromObject({
        year: Number(year),
        month: Number(month),
        day: Number(day),
      })

      if (date.isValid) {
        this.field.props = Object.assign({}, this.field.props, { mask: myKadMask })
        // if (val.length !== 12) return
      }

      // this.$nextTick(() => { this.field.props.loading = true })
      // this.validateIdForB10()
    },
  },
  mounted: function () {
    this.field.props = Object.assign({
      clearable: true,
      loading: false,
      mask: idMask,
      maxlength: 12,
      persistentHint: true,
      required: true,
      validateOnBlur: true,
    }, this.field.props)
  },
  methods: {
    validateIdForB10: function () {
      const params = createGetParams({
        id: this.field.value,
      })

      this.source = this.$rest.CancelToken.source()
      Object.assign(params, { cancelToken: this.source.token })
      this.field.loading = true
      setTimeout(() => {
        this.$rest.get('validateIdForB10.php', params)
          .then(response => {
            this.$nextTick(() => { this.field.props.loading = false })
            if (response.data.warn) {
              this.alertText = response.data.warn
              this.notification = true
            }
          }).catch(e => {
            this.$nextTick(() => { this.field.props.loading = false })
          })
      }, 1000)
    },
  },
}
</script>
